export const STEPS = {
    EDIT_DETAILS: 'EDIT_DETAILS',
    UPLOAD_PHOTOS: 'UPLOAD_PHOTOS',
    TAG_PHOTOS: 'TAG_PHOTOS',
    PREVIEW_PROJECT: 'PREVIEW_PROJECT',
} as const;

export const PROJECT_STATUSES = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    DRAFT: 'DRAFT',
} as const;

export const PUBLISHED_PROJECT_QUERY = 'publishedProject';
