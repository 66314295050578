import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type TrashCanProps = {
    className?: string;
};

const TrashCan: FunctionComponent<TrashCanProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'trash-can'}
            viewBox="0 0 250 250"
        >
            <path d="M240 31h-60.3l-3.9-16.2v-.2c-.1-.4-1.2-3.9-4.2-7.4-2.9-3.3-8.1-7.2-16.7-7.2H92.1c-.4 0-3.6.1-7.4 1.9-3.5 1.7-8.1 5.3-10.3 12.6L71.7 31H10v9h18.8l15.7 185.5c0 1.8.3 9.8 6.4 16.3 5.2 5.4 12.8 8.2 22.8 8.2h111.7c7.9-.9 23-7.3 23.6-25.5L223.3 40H240v-9zM83.1 16.9C85.4 9.6 91.4 9 92.2 9h62.5c9.4 0 12 7 12.3 8l3.4 14H80.8l2.3-14.1zM200 223.8v.2c-.4 14.2-13.1 16.6-15.4 16.9h-111c-7.3 0-12.8-1.8-16.2-5.3-4.2-4.3-4-10.1-4-10.1v-.3L37.8 40h176.4L200 223.8z" />
        </svg>
    );
};
export default TrashCan;
