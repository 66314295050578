import { useEffect, useState, useRef, useCallback } from 'react';
import { graphql, fetchQuery_DEPRECATED as fetchQuery, type Environment } from 'react-relay/legacy';
import debounce from 'lodash.debounce';
import cuid from 'cuid';
import { type ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

import {
    type useAddressAutocompleteQuery as AutocompleteQuery,
    type useAddressAutocompleteQuery$data as AutocompleteResponse,
    type useAddressAutocompleteQuery$variables as AutocompleteResponseVariables,
} from './__generated__/useAddressAutocompleteQuery.graphql';
import {
    type useAddressAutocompleteDetailsQuery as AddressDetailsQuery,
    type useAddressAutocompleteDetailsQuery$data as AddressDetailsResponse,
} from './__generated__/useAddressAutocompleteDetailsQuery.graphql';

export type Suggestion = ArrayElement<AutocompleteResponse['viewer']['addressAutocomplete']>;
export type AddressDetails = AddressDetailsResponse['viewer']['addressAutocompleteDetails'];

const autocompleteQuery = graphql`
    query useAddressAutocompleteQuery(
        $addressInput: String!
        $sessionToken: String!
        $placeType: String
    ) {
        viewer {
            addressAutocomplete(
                addressInput: $addressInput
                sessionToken: $sessionToken
                placeType: $placeType
            ) {
                placeId
                displayValue
                addressLine
            }
        }
    }
`;

const addressDetailsQuery = graphql`
    query useAddressAutocompleteDetailsQuery($placeId: String!, $sessionToken: String!) {
        viewer {
            addressAutocompleteDetails(placeId: $placeId, sessionToken: $sessionToken) {
                country
                city
                zipCode
                stateOrRegion
            }
        }
    }
`;

type Props = {
    input: string;
    placeType?: string;
    environment: Environment;
};

type Return = {
    suggestions: Suggestion[];
    getAddressDetails: (selectedSuggestion: Suggestion) => Promise<AddressDetails>;
};

const useAddressAutocomplete = ({ input, environment, placeType }: Props): Return => {
    const [suggestions, setSuggestions] = useState<Suggestion[] | null>([]);
    const latestAutocompleteQueryInput = useRef<
        AutocompleteResponseVariables['addressInput'] | null | undefined
    >('');
    const sessionToken = useRef(cuid());

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const queryAutocomplete = useCallback(
        async (
            addressInput?: AutocompleteResponseVariables['addressInput'] | null
        ): Promise<void> => {
            latestAutocompleteQueryInput.current = addressInput;

            if (addressInput) {
                const result = await fetchQuery<AutocompleteQuery>(environment, autocompleteQuery, {
                    addressInput,
                    sessionToken: sessionToken.current,
                    placeType,
                });

                if (isMounted.current) {
                    const newSuggestions = result?.viewer?.addressAutocomplete as Suggestion[];
                    setSuggestions(newSuggestions);
                }
            }
        },
        [environment, placeType]
    );

    const debouncedQueryAutocomplete = useCallback(debounce(queryAutocomplete, 250), [
        queryAutocomplete,
    ]);

    const queryAddressDetails = async (selectedSuggestion: Suggestion): Promise<AddressDetails> => {
        const { placeId, addressLine } = selectedSuggestion || {};

        // load suggestions on exact selected address before finalizing the session
        queryAutocomplete(addressLine);

        const result = await fetchQuery<AddressDetailsQuery>(environment, addressDetailsQuery, {
            placeId: placeId || '',
            sessionToken: sessionToken.current,
        });
        sessionToken.current = cuid();

        return result.viewer.addressAutocompleteDetails;
    };

    useEffect(() => {
        const hasInputChangedAfterLastQuery = input !== latestAutocompleteQueryInput.current;
        if (input?.length > 0 && hasInputChangedAfterLastQuery) {
            debouncedQueryAutocomplete(input);
        }
    }, [input, debouncedQueryAutocomplete]);

    return { suggestions: suggestions || [], getAddressDetails: queryAddressDetails };
};

export default useAddressAutocomplete;
