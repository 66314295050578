import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type PenLineProps = {
    className?: string;
    showLine?: boolean;
};

const PenLine: FunctionComponent<PenLineProps> = props => {
    const { className, showLine = true } = props;
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, className)}
            data-tn={'pencil-line'}
            viewBox="0 0 250 250"
        >
            {showLine && <rect x="2.8" y="234.1" width="244" height="9" />}
            <path d="M219.9 83.2l-45.1-58L35.6 137.6 5.7 215.8l72.8-20.2.3.4.9-.7 3.6-1 .7-.6L219.9 83.2zM40.8 149l31.5 39-51.9 14.4L40.8 149zm166.6-67.2L81.1 184.5l-35.3-43.6L173.3 38l34.1 43.8z" />
            <path d="M244.8 60.1c1.5-3.7 2.2-9.7-2.7-17.2-.7-1-2.8-3.5-4.5-5.5-2.3-2.7-5.1-6-8.1-9.4-5.4-6.2-12.1-13.6-14.5-15.8-4.5-4.2-9.6-5.6-14.8-4.1-.7.2-1.4.5-2.1.7l-.3.1-20 13.1 45.9 58 16.8-13.7c.1-.1.2-.2.4-.3l1.2-1.2c1.1-1.5 2.1-3.1 2.7-4.7zm-8.3-3.4c-.4.9-.9 1.7-1.3 2.1l-.5.5-9.6 7.9-34.1-43 11.2-7.3c.2-.1.4-.2.6-.2 1.4-.4 3.4-.5 6.2 2.1 1.7 1.6 7.3 7.7 13.8 15.1 2.9 3.3 5.7 6.6 8 9.3 2.6 3.1 3.6 4.4 3.8 4.7 3.4 5.1 2.3 7.9 1.9 8.8z" />
            )
        </svg>
    );
};

export default PenLine;
