import { type FunctionComponent } from 'react';
import PenLine from './PenLine';

type PenProps = {
    className?: string;
};

const Pen: FunctionComponent<PenProps> = props => {
    return <PenLine {...props} showLine={false} />;
};
export default Pen;
