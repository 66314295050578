import { type ReactElement } from 'react';

import { AutocompleteInput } from '../AutocompleteInput';
import { type Props as AutocompleteProps, type Option } from '../AutocompleteInput';

import styles from './main.scss';

function AddressAutocomplete<T extends Option>(props: AutocompleteProps<T>): ReactElement {
    return (
        <AutocompleteInput
            {...props}
            dropdownShadow
            dropdownAppend={
                <div className={styles.poweredByGoogle}>
                    <img
                        src="https://a.1stdibscdn.com/dist/adhoc/powered-by-google-hdpi.png"
                        alt="powered-by-google"
                    />
                </div>
            }
        />
    );
}

export { AddressAutocomplete };
