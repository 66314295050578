import { type ReactElement, type ReactNode, type ChangeEventHandler } from 'react';
import { useCombobox } from 'downshift';

import { Dropdown } from 'dibs-elements/exports/Dropdown';
import { Input } from 'dibs-elements/exports/Input';

import styles from './main.scss';

export type Option = { displayValue: ReactNode | null } | null;

export type Props<T extends Option> = {
    value: string;
    name?: string;
    dataTn: string;
    label?: ReactNode;
    placeholder?: string;
    options: T[];
    errorMessage?: ReactNode;
    autoFocus?: boolean;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    disabled?: boolean;
    autoComplete?: string;
    hasValidText?: boolean;
    onSelect: (selectedValue: T | null | undefined) => void;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur?: ChangeEventHandler<HTMLInputElement>;
    onFocus?: ChangeEventHandler<HTMLInputElement>;
    hasAnimatedPlaceholder?: boolean;
    dropdownShadow?: boolean;
    rightDecorator?: ReactNode;
    dropdownAppend?: ReactNode;
};

const noop = (): void => {};

function AutocompleteInput<T extends Option>({
    value,
    name,
    dataTn,
    label,
    placeholder,
    options,
    errorMessage,
    autoFocus,
    size,
    className,
    disabled,
    autoComplete,
    hasValidText,
    onSelect,
    onChange,
    onBlur = noop,
    onFocus = noop,
    hasAnimatedPlaceholder,
    rightDecorator,
    dropdownAppend,
    dropdownShadow,
}: Props<T>): ReactElement {
    const {
        isOpen,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getInputProps,
        getComboboxProps,
    } = useCombobox({
        items: options,
        onSelectedItemChange: ({ selectedItem }) => onSelect(selectedItem),
    });

    const inputProps = getInputProps({ onBlur, onChange, onFocus });

    return (
        <div {...getComboboxProps()}>
            <Input
                type="text"
                maxLength={256}
                dataTn={dataTn}
                name={name}
                value={value}
                size={size}
                label={label}
                placeholder={placeholder}
                errorMessage={errorMessage}
                className={className}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                hasValidText={hasValidText}
                hasAnimatedPlaceholder={hasAnimatedPlaceholder}
                onChange={inputProps.onChange}
                onBlur={inputProps.onBlur}
                onFocus={inputProps.onFocus}
                onKeyDown={inputProps.onKeyDown}
                inputRef={inputProps.ref}
                rightDecorator={rightDecorator}
                maskForPrivacy
            />
            <div className={styles.dropdownContainer}>
                <Dropdown
                    dataTn={`${dataTn}-dropdown`}
                    isOpen={isOpen && value.length > 0 && options.length > 0}
                    hasShadow={dropdownShadow}
                    hideTopBorder
                    matchContainerWidth
                >
                    <ul id={`${dataTn}-datalist`} className={styles.datalist} {...getMenuProps()}>
                        {options.map((option, index) => (
                            <li
                                className={`${styles.option} ${
                                    highlightedIndex === index ? styles.highlighted : ''
                                }`}
                                value={option}
                                key={`${dataTn}-option-${option?.displayValue}`}
                                {...getItemProps({ item: option, index })}
                            >
                                {option?.displayValue || ''}
                            </li>
                        ))}
                    </ul>
                    {dropdownAppend}
                </Dropdown>
            </div>
        </div>
    );
}

export { AutocompleteInput };
