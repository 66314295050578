import {
    type FC,
    useCallback,
    useState,
    type ReactNode,
    type ComponentPropsWithoutRef,
} from 'react';
import { useRelayEnvironment } from 'react-relay';
import { AddressAutocomplete } from 'dibs-elements/exports/AddressAutocomplete';
import { Spinner } from 'dibs-elements/exports/Spinner';
import useAddressAutocomplete, {
    type Suggestion,
    type AddressDetails,
} from 'dibs-address-form/exports/useAddressAutocomplete';

const noop = (): void => {};

// capture the props of AddressAutocomplete, but skip the props this component will pass down itself
type AddressAutocompleteProps = Omit<
    ComponentPropsWithoutRef<typeof AddressAutocomplete>,
    | 'value'
    | 'options'
    | 'dataTn'
    | 'autoComplete'
    | 'label'
    | 'placeholder'
    | 'onSelect'
    | 'onChange'
>;

export type { AddressDetails };

type Props = AddressAutocompleteProps & {
    onChange: (value: string) => void;
    onSelect?: (value: string) => void;
    onDetailsReceived: (details: AddressDetails) => void;
    label?: ReactNode;
    placeholder?: string;
    value: string;
};

const LocationInputAutocomplete: FC<Props> = props => {
    const {
        label,
        placeholder,
        value,
        onChange,
        onDetailsReceived,
        onSelect = noop,
        ...rest
    } = props;

    const environment = useRelayEnvironment();

    const [isLoading, setIsLoading] = useState(false);

    const { suggestions, getAddressDetails } = useAddressAutocomplete({
        input: value,
        environment,
        placeType: 'geocode',
    });

    const handleSelect = useCallback(
        async (selected?: Suggestion | null): Promise<void> => {
            const { addressLine } = selected || {};
            onSelect(addressLine || '');
            setIsLoading(true);

            const addressDetails = await getAddressDetails(selected ?? null);
            const { country, city, stateOrRegion, zipCode } = addressDetails || {};

            setIsLoading(false);

            onDetailsReceived({
                country: country || '',
                city: city || '',
                stateOrRegion: stateOrRegion || '',
                zipCode: zipCode || '',
            });
        },
        [onSelect, getAddressDetails, onDetailsReceived]
    );

    // if you add a prop here, make sure it's added to AddressAutocompleteProps above so it's no longer required for components that use this
    return (
        <AddressAutocomplete<Suggestion>
            {...rest}
            value={value}
            options={suggestions || []}
            dataTn="location-input-autocomplete"
            autoComplete="location-input"
            label={label}
            placeholder={placeholder}
            onSelect={handleSelect}
            onChange={e => onChange(e.target.value)}
            rightDecorator={isLoading && <Spinner size="tiny" />}
        />
    );
};

export default LocationInputAutocomplete;
